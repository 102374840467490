import { DOCUMENT } from '@angular/common';
import { inject, Injectable, RendererFactory2 } from '@angular/core';

declare global {
  interface Window {
    dataLayer: unknown[];
  }
}

export type GoogleAnalyticsProperties = Record<string, string>;

@Injectable({ providedIn: 'root' })
export class GoogleAnalyticsService {
  readonly #document = inject(DOCUMENT);
  readonly #renderer2 = inject(RendererFactory2).createRenderer(null, null);

  #tagId?: string;

  addProperties(props: GoogleAnalyticsProperties): void {
    if (!this.#tagId) {
      return;
    }

    window.dataLayer.push(props);
  }

  identify(userId: string): void {
    if (!this.#tagId) {
      return;
    }

    if (!userId) {
      console.warn('GoogleAnalyticsService.identify: userId is not defined');

      return;
    }

    window.dataLayer.push({
      // eslint-disable-next-line camelcase
      user_id: userId,
    });
  }

  initialize(tagId?: string): void {
    if (!tagId) {
      return;
    }

    this.#tagId = tagId;
    this.#createGAScript(tagId);
  }

  removeProperties(props: string[]): void {
    if (!this.#tagId) {
      return;
    }

    const properties: GoogleAnalyticsProperties = {};

    props.forEach((prop) => {
      properties[prop] = '<No value>';
    });

    window.dataLayer.push(properties);
  }

  #createGAScript(tagId: string): void {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'gtm.start': Date.now(),
      event: 'gtm.js',
    });

    const script = this.#renderer2.createElement('script') as HTMLScriptElement;
    const scriptSrc = `https://www.googletagmanager.com/gtm.js?id=${tagId}`;

    this.#renderer2.setAttribute(script, 'async', '');
    this.#renderer2.setAttribute(script, 'src', scriptSrc);
    this.#renderer2.appendChild(this.#document.head, script);
  }
}
